.App {
  text-align: center;
  padding: 20px;
  margin: 0 auto;
  max-width: 100%;
  box-sizing: border-box; /* Ensure padding doesn't add to width */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  max-width: 100%; /* Ensure logo doesn't overflow on small screens */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#hero {

  background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url("/public/images/hero1.jpg");
}

.App-header {
  background-color: #282c34;
  height: 100%; /* Adapt better on mobile screens */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem; /* Use a standard font size */
  color: white;
  padding: 20px; /* Add padding for better spacing */
  box-sizing: border-box;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  margin-top: 20px; /* Ensure there's spacing between links */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px; /* Add padding for better spacing */
  box-sizing: border-box;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  background-color: #282c34; /* Add consistent background */
  color: white;
}

.container {
  max-width: 100%;
}

.request-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.request-item {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
  background-color: #fff;
}

.request-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.request-label {
  font-weight: 600;
  color: #333;
  flex-basis: 30%;
}

.request-value {
  flex-basis: 70%;
  color: #555;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.request-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

@media (max-width: 576px) {
  .request-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .request-label {
    margin-bottom: 0.25rem;
  }

  .request-avatar {
    width: 30px;
    height: 30px;
  }
}

.navigation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 1px solid #f0f2f4;
}

.navigation-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navigation-brand {
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
}

.brand-title {
  color: #111418;
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 0.5rem;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 18px;
  cursor: pointer;
}

.hamburger-bar {
  height: 3px;
  width: 100%;
  background-color: #111418;
}

.navigation-links {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.navigation-link {
  color: #111418;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
}

.create-button {
  padding: 0.5rem 1.5rem;
  background-color: #1980e6;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.mobile-separator {
  display: none; /* Hide by default */
  border: 0;
  height: 1px;
  background-color: #000; /* Change to match your design */
  margin: 1rem 0; /* Add spacing as needed */
}


@media (max-width: 768px) {
  .hamburger-menu {
    display: flex;
  }

  .mobile-separator {
    display: block; /* Show on mobile screens */
    height: 1px;
    align-self: center;
    width: 100%;
  }

  .navigation-links {
    position: absolute;
    top: 70px;
    right: 0;
    background-color: white;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 1rem 2rem;
    display: none;
    z-index: 51;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .navigation-links.open {
    display: flex;
  }

  .navigation-link {
    width: 100%;
    text-align: left;
    padding: 0.75rem 0;
    margin: 1px;
    align-self: center;
    text-align: center;
  }

  .create-button {
    margin-top: 1rem;
    width: 50%;
    align-self: center;
    text-align: center;
  }
}
.find-event-container {
  padding: 1.25rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.header-title {
  color: #111418;
  font-size: 2rem;
  font-weight: 900;
}

.filter-container {
  display: flex;
  border-bottom: 1px solid #dce0e5;
  padding-bottom: 0.75rem;
  margin-bottom: 1.5rem;
}

.filter-link {
  color: #637588;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  margin-right: 1rem;
}

.filter-link.active {
  color: #111418;
  border-color: #111418;
}

.events-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.no-events {
  color: #111418;
  font-size: 1rem;
  text-align: center;
  padding: 2rem 0;
}

.event-item {
  border: 1px solid #dce0e5;
  border-radius: 8px;
  padding: 1rem;
  background-color: #fff;
}

.event-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.event-label {
  font-weight: 600;
  color: #111418;
}

.event-status {
  background-color: #f0f2f4;
  color: #111418;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
}

.event-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.event-action-button {
  background-color: #1980e6;
  color: white;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  display: block;
  width: 100%;
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .events-list {
    gap: 1.25rem;
  }

  .event-item {
    padding: 1rem;
  }

  .event-detail {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .event-actions {
    justify-content: center;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
